<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader>
            <strong>Data AKUN SAKTI </strong><small>Tambah Data</small>
          </CCardHeader>
          <CCardBody>
            <CForm @submit.prevent="inputUPT">
            <CRow>
              <CCol sm="6">
                <CInput
                  v-model="kode_upt"
                  label="Kode UPT"
                  placeholder="Input Kode UPT"
                />
              </CCol>
              <CCol sm="12">
                <CInput
                  v-model="nama_upt"
                  label="Nama UPT"
                  placeholder="Input Nama UPT"
                />
              </CCol>
              <CCol sm="6">
                <div class="form-group">
                <label>Status Aktif</label>
                  <select v-model="status_aktif_upt" class="form-control">
                    <option value="" disabled selected>-- Pilihan --</option>
                    <option value="1">Aktif</option>
                    <option value="0">Nonaktif</option>
                  </select>
                </div>
              </CCol>         
            </CRow>          
              <div class="form-actions float-right">
                <CButton type="submit" color="primary" style='margin-right:15px'>Simpan</CButton>
                <CButton color="secondary" to="/upt">Batal</CButton>
              </div>
            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <CModal
      title="Error Input"
      color="danger"
      id="modalError"
    >
      <b>Error</b>! Data gagal diinput.
    </CModal>
   
  </div>
</template>
<script>
import InputTag from 'vue-input-tag'
import axios from 'axios';
import * as Modals from '../notifications/Modals.vue'
// import { CurrencyInput } from 'vue-currency-input'

export default {
  // components: {CurrencyInput },
  name: 'Forms',
  components: { 
    InputTag ,
  },
  data () {
    return {
      kode_upt : "",
      nama_upt : "",
      status_aktif_upt : "",
      selected: [], // Must be an array reference!
      // upts: [],
      show: true,
    }
  },
  mounted(){
  // this.loadData();
  },
  
  methods: {

    // loadData: function(event){
      
    //   let username =window.btoa(this.$route.params.id_user);
    //   console.log(username);

    //   axios.get(process.env.VUE_APP_BASE_URL+"pegawais")
    //     .then((response) => {
    //           // this.upts=response.data;
    //           const datas = response.data;
              
    //           this.upts = datas.map(d=>{return d['nama_pegawai']});;
    //           console.log("datas::"+this.upts)
    //     });
    // },

    inputUPT: function(event){

      const formData = new FormData();
        
        formData.append('kode_upt', this.kode_upt)
        formData.append('nama_upt', this.nama_upt)
        formData.append('status_aktif_upt', this.status_aktif_upt)
      
      // alert(JSON.stringify(anggota));
      axios.post(process.env.VUE_APP_BASE_URL+"upt", formData)
        .then((response) => {
            // console.log(response);
            // alert(response.data);
            if(response.status==200){
              this.$router.push('/upt');
            }
            else{
              this.modalError = true;
            }            
        })
    }
  }
}
</script>